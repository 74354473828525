<template>
  <div class="up">
    <van-nav-bar
      placeholder
      fixed
      left-arrow
      :border="false"
      title="设置"
      z-index="99"
      @click-left="$router.go(-1)"
    >
      <template #left>
        <van-icon name="arrow-left" size="25" color="#0A1C33" />
      </template>
    </van-nav-bar>
    <div>
      <!--            <van-uploader :after-read="afterRead" v-model="fileList"/>-->
      <van-uploader class="uploader" :after-read="afterRead">
        <div class="up-list" @click="headClick">
          <p class="up-title">头像</p>
          <div class="up-right-list">
            <p class="up-right">
              <img :src="user_infoList.avatar" alt="" class="set_def_tx" />
            </p>
            <p>
              <van-icon name="arrow" color="#BFC2CC" />
            </p>
          </div>
        </div>
      </van-uploader>
      <div class="up-list" @click="nicknameClick">
        <p class="up-title">昵称</p>
        <div class="up-right-list">
          <p class="up-right-text">
            {{ user_infoList.nickname }}
          </p>
          <p>
            <van-icon name="arrow" color="#BFC2CC" />
          </p>
        </div>
      </div>
      <div class="up-list" @click="genderClick">
        <p class="up-title">性别</p>
        <div class="up-right-list">
          <p class="up-right-text">
            <template v-if="user_infoList.gender == '0'"
              >请选择你的性别</template
            >
            <template v-if="user_infoList.gender == '1'">男</template>
            <template v-if="user_infoList.gender == '2'">女</template>
          </p>
          <p>
            <van-icon name="arrow" color="#BFC2CC" />
          </p>
        </div>
      </div>
      <div class="up-list" @click="personalityClick">
        <p class="up-title">个性签名</p>
        <div class="up-right-list">
          <p class="up-right-text">
            {{ user_infoList.sign }}
          </p>
          <p>
            <van-icon name="arrow" color="#BFC2CC" />
          </p>
        </div>
      </div>
      <p class="xian"></p>
      <div class="up-list" @click="bindingClick">
        <p class="up-title">账号和绑定设置</p>
        <div class="up-right-list">
          <p class="up-right-text"></p>
          <p>
            <van-icon name="arrow" color="#BFC2CC" />
          </p>
        </div>
      </div>
      <p class="xian"></p>
      <div class="up-login" @click="loginClick">退出登录</div>

      <p class="xian"></p>
      <div class="up-logoutaccount" @click="logoutaccount">注销账号</div>
      <div class="agreement">
          <router-link to="/agreement">《用户协议》</router-link>
          <router-link to="/privacy">《隐私政策》</router-link>
      </div>
    </div>

    <!--        头像设置-->
    <!--        <van-action-sheet-->
    <!--                v-model="show"-->
    <!--                :actions="actions"-->
    <!--                cancel-text="取消"-->
    <!--                close-on-click-action-->
    <!--                @cancel="onCancel"-->
    <!--                @select="headSelect"-->
    <!--        />-->
    <!--        昵称设置-->
    <van-action-sheet
      v-model="nickname_show"
      title="修改昵称"
      close-icon="success"
      @cancel="SuccessClick"
    >
      <div class="book">
        <textarea placeholder="请输入昵称~" maxlength="10" v-model="nickname">
        </textarea>
        <p class="book-textarea">2-10个字符，允许中文、英文、数字</p>
      </div>
    </van-action-sheet>
    <!--        性别设置-->
    <van-action-sheet
      v-model="gender_show"
      :actions="gender"
      cancel-text="取消"
      close-on-click-action
      @cancel="onCancel"
      @select="genderSelect"
    />
  </div>
</template>

<script>
export default {
  name: "Up",
  data() {
    return {
      fileList: [],
      show: false,
      actions: [
        { id: "0", name: "拍照" },
        { id: "1", name: "从手机相册选择" },
      ],
      gender_show: false,
      gender: [
        { id: "1", name: "男" },
        { id: "2", name: "女" },
        { id: "0", name: "保密" },
      ],
      nickname_show: false,
      nickname: "",
      user_infoList: [],
    };
  },
  created() {
    this.user_info();
  },
  methods: {
    user_info() {
      this.$axios.get("/api/user_info").then((res) => {
        this.user_infoList = res.data.data;
        this.nickname = this.user_infoList.nickname;
      });
    },
    afterRead(e) {
      this.$toast.loading({
        message: "修改中...",
        duration: 0,
        overlay: true,
      });
      let file = e.file;
      let formData = new FormData();
      formData.append("targetFile", file);
      this.$ajax({
        method: "POST",
        url: "/api/common/uploadFile",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        data: formData,
      })
        .then((res) => {
          var res = res.data.data.file_name;
          this.user_infoList.avatar = res;
          this.save_avatar(res);
        })
        .catch((err) => {
          this.$toast(err.response.data.message);
        });
    },
    save_avatar(avatar) {
      this.$axios
        .post("/api/save_avatar", {
          avatar: avatar,
        })
        .then((res) => {
          this.$toast.success("修改头像成功");
        });
    },
    SuccessClick() {
      if (this.nickname.length < 2) {
        this.$toast("昵称不得少于2个字符");
        this.nickname_show = true;
      } else {
        this.nickname_show = false;
        this.$axios
          .post("/api/save_nickname", {
            nickname: this.nickname,
          })
          .then((res) => {
            this.$toast.success("修改成功");
            this.user_infoList.nickname = this.nickname;
          });
      }
    },
    personalityClick() {
      this.$router.push({
        path: "/personality",
        query: {
          personality: this.user_infoList.sign,
        },
      });
    },
    bindingClick() {
      this.$router.push({
        path: "/binding",
      });
    },
    nicknameClick() {
      this.nickname_show = true;
    },
    genderClick() {
      this.gender_show = true;
    },
    genderSelect(item) {
      this.$axios
        .post("/api/save_gender", {
          gender: item.id,
        })
        .then((res) => {
          this.$toast.success("修改成功");
          this.user_infoList.gender = item.id;
        });
    },
    headClick() {
      this.show = true;
    },
    headSelect(item) {
      console.log(item.id);
    },
    onCancel() {
      this.show = false;
      this.gender_show = false;
    },
    loginClick() {
      this.$dialog
        .alert({
          title: "退出登录",
          message: "确定要退出嘛？",
          confirmButtonText: "退出",
          confirmButtonColor: "#D53D3C",
          cancelButtonColor: "#606266",
          cancelButtonText: "再看看",
          showCancelButton: "true",
        })
        .then(() => {
          this.$axios.post("/api/logout").then((res) => {
            let ExemptAD_time = 0;
            if (window.localStorage.getItem("ExemptAD_time") != null) {
              ExemptAD_time = window.localStorage.getItem("ExemptAD_time") * 1;
            }
            localStorage.clear();
            if (ExemptAD_time != 0) {
              window.localStorage.setItem("ExemptAD_time", ExemptAD_time + "");
            }

            this.$toast.success("退出成功");
            this.$router.replace({
              path: "/my",
            });
          });
          // on confirm
        })
        .catch(() => {
          // on cancel
        });
    },
    logoutaccount() {
      this.$dialog
        .alert({
          title: "注销账号",
          message: "确定要注销嘛？",
          confirmButtonText: "注销",
          confirmButtonColor: "#D53D3C",
          cancelButtonColor: "#606266",
          cancelButtonText: "再看看",
          showCancelButton: "true",
        })
        .then(() => {
          this.$axios.post("/api/cancellation").then((res) => {
            let ExemptAD_time = 0;
            if (window.localStorage.getItem("ExemptAD_time") != null) {
              ExemptAD_time = window.localStorage.getItem("ExemptAD_time") * 1;
            }
            localStorage.clear();
            if (ExemptAD_time != 0) {
              window.localStorage.setItem("ExemptAD_time", ExemptAD_time + "");
            }

            this.$toast.success("注销成功");
            this.$router.replace({
              path: "/my",
            });
          });
          // on confirm
        })
        .catch(() => {
          // on cancel
        });
    },
  },
};
</script>

<style scoped>
.book-textarea {
  color: #bfc2cc;
  font-size: 24px;
  text-align: right;
  padding: 40px 0;
}

.book {
  padding: 0 40px;
}

textarea::-webkit-input-placeholder {
  font-size: 28px;
  color: #bfc2cc;
  line-height: 44px;
}

textarea {
  border: none;
  height: 300px;
  width: 100%;
  padding: 40px 20px;

  font-size: 28px;
  color: #303133;
}

.up {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f2f5f8;
}

.up-login {
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #d53d3c;
  font-size: 26px;
  background: #fff;
}

.up-logoutaccount {
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #d53d3c;
  font-size: 26px;
  background: #fff;
}

.xian {
  height: 20px;
  background: #f2f5f8;
}

.up-right-text {
  text-align: right;
  color: #bfc2cc;
  font-size: 26px;
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.up-title {
  color: #0a1c33;
  font-size: 28px;
}
.agreement{
    text-align: center;
    margin-top: 60px;
}
.up-right-list {
  display: flex;
  align-items: center;
}

.up-list {
  padding: 0 40px;
  border-bottom: 1px solid #f2f5f8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 110px;
  background: #fff;
}

.set_def_tx {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background: url("../../assets/images/set_def_tx.png") no-repeat;
  background-size: cover;
  display: inline-block;
  margin-right: 10px;
}
</style>